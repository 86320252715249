<template>
  <div class="mini_calendar">
    <div class="mini_staff_monthly_performance">
      <div class="mini_schedule_actual_monthly_performance"></div>
      <div class="mini_total_w_compare_block">
        за месяц:
        <span
          class="mini_total_w_value"
          :class="{
            'mini_total_w_completed': monthlyHoursNormCompleted,
            'mini_total_w_not_completed': !monthlyHoursNormCompleted,
          }"
        >
          {{  monthlyTotalActualHour }}
        </span> /
        <span class="mini_total_w_value">
          {{ monthlyTotalScheduleHour }}
        </span>
      </div>
    </div>
    <table class="mini_calendar_table">
      <thead>
      <tr class="mini_calendar_header_tr">
        <th class="mini_calendar_th" v-for="day in daysOfWeek" :key="day">{{ day }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(week, weekIndex) in calendarRows" :key="weekIndex">
        <td v-for="(dayData, dayIndex) in week" :key="dayIndex" class="mini_calendar_td">
          <mini-staff-calendar-day v-if="dayData" :dayData="dayData"/>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="mini_calendar_legend">
    </div>
  </div>
</template>
<script>
import MiniStaffCalendarDay from "./mini-staff-calendar-day.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    staff_id: {
      type: Number,
    },
    select_date: {
      type: String
    },
    daysOfWeek: {
      type: Array,
      default: () => ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вск']
    }
  },
  components: {
    MiniStaffCalendarDay,
  },
  data() {
    return {
      dayData: []
    }
  },
  mounted() {
    this.fetchCalendarData();
  },
  watch: {
    select_date: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.fetchCalendarData();
        }
      },
      deep: true,
      immediate: true,
    }
  },
  computed: {
    monthlyHoursNormCompleted() {
      if (this.dayData.month_performance) {
        const actual = parseFloat(this.dayData.month_performance.total_act_w_month_common || 0);
        const schedule = parseFloat(this.dayData.month_performance.total_sch_w_month_common || 0);
        if (actual > schedule) {
          return true;
        }
      }
    },
    monthlyTotalScheduleHour() {
      if (this.dayData.month_performance) {
        return this.parseTimeFromDecimalToHm(this.dayData.month_performance.total_sch_w_month_common)
      }
    },
    monthlyTotalActualHour() {
      if (this.dayData.month_performance) {
        return this.parseTimeFromDecimalToHm(this.dayData.month_performance.total_act_w_month_common)
      }
    },
    calendarOffset() {
      return this.dayData.month_first_day_offset;
    },
    daysInMonth() {
      return this.dayData.days_in_month;
    },
    calendarRows() {
      let days = [];

      for (let i = 0; i < this.calendarOffset; i++) {
        days.push(null);
      }

      for (let i = 0; i < this.daysInMonth; i++) {
        days.push(this.dayData.month_schedule_and_actual[i]);
      }

      while (days.length % 7 !== 0) {
        days.push(null);
      }

      return this.chunkArray(days, 7);
    },
    ...mapGetters({
      newCalendarData: "staffCalendar/list"
    }),
  },
  methods: {
    ...mapActions({
      getNewCalendarData: "staffCalendar/index"
    }),
    chunkArray(array, size) {
      let chunkedArray = [];
      for (let i = 0; i < array.length; i += size) {
        chunkedArray.push(array.slice(i, i + size));
      }
      return chunkedArray;
    },
    fetchCalendarData() {
      const params = {staff_id: this.staff_id, date: this.select_date};
      this.getNewCalendarData(params).then((res) => {
        this.dayData = res;
        console.log(this.dayData)
      }).catch((err) => {
        console.log('printErr', err);
      });
    },
    parseTimeFromDecimalToHm(timeInDecimalHm) {
      const hours = Math.floor(timeInDecimalHm);
      const minutesDecimal = (timeInDecimalHm - hours) * 60;
      const minutes = Math.round(minutesDecimal);
      const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;
      return hours + ":" + paddedMinutes;
    }
  }
}
</script>
<style lang="css">
.mini_calendar {
  box-sizing: content-box;
  background: white;
  margin-bottom: 2vw;
  padding-bottom: 0.2vw;
  overflow: hidden;
  border-radius: 0.6rem;
  border: 1px solid #ececec;
}

.mini_staff_monthly_performance {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 0;
  border-bottom: solid 1px #ececec;
}

.mini_calendar_td {
  position: relative;
  border-collapse: collapse;
  padding: 3px;
  width: calc(100vw / 7);
  height: calc(100vw / 7);
}

.mini_calendar_header_tr {
  height: 30px;
}

.mini_calendar_th {
  border-bottom: none;
  font-size: 12px;
  font-weight: 900;
}

.mini_calendar_table {
  align-self: center;
  width: 100%;
  min-height: fit-content;
}

.mini_total_w_compare_block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 4vw;
  font-weight: 900;
}

.mini_total_w_completed {
  color: #6aad45;
  font-weight: 900;
  font-size: 14px;
}

.mini_total_w_not_completed {
  color: #ea913b;
  font-weight: 900;
}

.mini_total_w_value {
  margin: 0 8px;
}
</style>
