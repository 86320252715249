<template>
  <div class="bg-cwe">
    <div class="container-my" v-if="staffCheck">
      <div class="">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="8">
            <div :class="mode ? 'cardtable__day' : 'cardtable__night'" class="card-table p-4 mb-5 text-center"
                 style="position:relative">
              <div class="demo-image__preview">
                <el-image
                  class="my-c-avatar"
                  style="width: 100px; height: 100px"
                  :src="
                          staff.image ? (baseUrl + staff.image.path) : '/img/avatar.jpeg'
                        "
                  :preview-src-list="[
                          staff.image ? (baseUrl + staff.image.path) : '/img/avatar.jpeg',
                        ]"
                >
                </el-image>
              </div>
              <div :class="mode ? 'text__day2' : 'text__night2'" class="mt-4 left_name">
                <h3>{{ staff.name + " " + staff.last_name }}</h3>
              </div>
            </div>
            <div :class="mode ? 'cardtable__day' : 'cardtable__night'" class="card-table p-4 pt-5 mb-5">
              <div class="itme-info-user co-isir mb-3 pb-2">
                <i :class="mode ? 'text__day2' : 'text__night2'" class="el-icon-office-building"></i>
                <span :class="mode ? 'text__day2' : 'text__night2'" v-if="staff.company">
                        {{ staff.company.name }}
                      </span>
              </div>
              <div :class="mode ? 'liniya__day' : 'liniya__night'" class="linya2 mt-3 mb-3"></div>
              <div class="itme-info-user co-isir mb-3 pb-2">
                <i :class="mode ? 'text__day2' : 'text__night2'" class="el-icon-school"></i>
                <span :class="mode ? 'text__day2' : 'text__night2'" v-if="staff.branch">
                        {{ staff.branch.name }}
                      </span>
              </div>
              <div :class="mode ? 'liniya__day' : 'liniya__night'" class="linya2 mt-3 mb-3"></div>
              <div class="itme-info-user co-isir mb-3 pb-2">
                <i :class="mode ? 'text__day2' : 'text__night2'" class="el-icon-suitcase"></i>
                <span :class="mode ? 'text__day2' : 'text__night2'" v-if="staff.department">
                        {{ staff.department.name }}
                      </span>
              </div>
              <div :class="mode ? 'liniya__day' : 'liniya__night'" class="linya2 mt-3 mb-3"></div>
              <div class="itme-info-user co-isir mb-3 pb-2">
                <i :class="mode ? 'text__day2' : 'text__night2'" class="el-icon-position"></i>
                <span :class="mode ? 'text__day2' : 'text__night2'" v-if="staff.position">
                        {{ staff.position.name }}
                      </span>
              </div>
              <div :class="mode ? 'liniya__day' : 'liniya__night'" class="linya2 mt-3 mb-3"></div>
              <div class="itme-info-user co-isir mb-3 pb-2">
                <i :class="mode ? 'text__day2' : 'text__night2'" class="el-icon-s-data"></i>
                <span :class="mode ? 'text__day2' : 'text__night2'" v-if="staff.graphic">
                        {{ staff.graphic.name }}
                      </span>
              </div>
              <div :class="mode ? 'liniya__day' : 'liniya__night'" class="linya2 mt-3 mb-3"></div>
              <div class="itme-info-user co-isir mb-3 pb-2">
                <i :class="mode ? 'text__day2' : 'text__night2'" class="el-icon-paperclip"></i>
                <a :class="mode ? 'text__day2' : 'text__night2'">
                  <b v-if="staff.status">
                          <span v-if="staff.status.code == 1" style="color: #67C23A;">
                            {{ staff.status.name }}
                          </span>
                    <span v-else-if="staff.status.code == -1" style="color:#F56C6C;">
                            {{ staff.status.name }}
                          </span>
                    <span v-else style="color:#E6A23C">
                            {{ staff.status.name }}
                          </span>
                  </b>
                </a>
              </div>
              <div :class="mode ? 'liniya__day' : 'liniya__night'" class="linya2 mt-3 mb-3"></div>
              <div class="itme-info-user co-isir">
                <i :class="mode ? 'text__day2' : 'text__night2'" class="el-icon-phone-outline"></i>
                <a :class="mode ? 'text__day2' : 'text__night2'" :href="'tel:+' + staff.phone_number">
                  +{{ staff.phone_number }}
                </a>
              </div>
              <div :class="mode ? 'liniya__day' : 'liniya__night'" class="linya2 mt-3 mb-3"></div>
              <div class="itme-info-user co-isir">
                <div class="text-muted">{{ $t("message.last_active") }}</div>
                <div :class="mode ? 'text__day2' : 'text__night2'">{{ staff.updated_at }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <mini-staff-calendar :staff_id="staff.id" :select_date="select_date"></mini-staff-calendar>
      <staff-bonuses :staff_id="staff.id" v-if="staff"></staff-bonuses>
      <staff-penalties :staff_id="staff.id" v-if="staff"></staff-penalties>
      <profile-take-assent :staff_id="staff.id"></profile-take-assent>
      <!-- end card Изн -->
      <advance-salary :staff_id="staff.id"></advance-salary>
      <!-- end card Аванс -->
      <profile-complaint :staff_id="staff.id"></profile-complaint>
      <!-- end card Жалобы -->
      <additional-work :staff_id="staff.id"></additional-work>
    </div>
    <el-drawer
      title="I'm outer Drawer"
      :visible.sync="showStaffAttendance"
      size="100%"
      :with-header="false"
      :with-footer="false"
      ref="show-tms"
      @opened="drawerStaffAttendanceOpened('show-staff-attendance')">
      <div>
        <show-staff-attendance
          :staff="selected"
          @closeStaffAttendanceModal="closeStaffAttendanceModal"
          :child="child"
          ref="show-staff-attendance"
        ></show-staff-attendance>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import staffBonuses from "../profile/tabs/components/staff-bonuses.vue";
import staffPenalties from "../profile/tabs/components/staff-penalties.vue";
import AdditionalWork from "../profile/tabs/components/additionalWork.vue";
import ProfileTakeAssent from "../profile/tabs/components/profileTakeAssent.vue";
import AdvanceSalary from "../profile/tabs/components/advanceSalary.vue";
import ProfileComplaint from "../profile/tabs/components/profileComplaint.vue";
import showStaffAttendance from '../profile/tabs/components/show-staff-attendance.vue';
import axios from "axios";
import moment from 'moment';

import {i18n} from "@/utils/i18n";
import MiniStaffCalendar from "./miniStaffCalendar/mini-staff-calendar.vue";
import CrmNewCalendar from "../profile/tabs/staffCalendar/crm-new-calendar.vue";

export default {
  name: "applicationCandidates",
  components: {
    CrmNewCalendar,
    MiniStaffCalendar,
    staffBonuses,
    staffPenalties,
    AdditionalWork,
    AdvanceSalary,
    ProfileComplaint,
    ProfileTakeAssent,
    showStaffAttendance
  },
  watch: {
    select_date: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.table();
        }
      },
      deep: true,
      immediate: true,
    }

  },
  data() {
    return {
      select_date: moment().format("YYYY-MM-DD"),
      additionals: {},
      advance_salaries: {},
      waiti: 0,
      deny: 0,
      accept: 0,
      selected: null,
      staffCheck: false,
      staffUuid: this.$route.params.staffUuid,
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      ),
      daily_reports: {},
      child: {},
      work_hour: null,
      options: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      showStaffAttendance: false,
    };
  },
  created() {
    this.fetchStaff();
    this.table();
  },
  computed: {
    ...mapGetters({
      staff: "openStaffProfile/model",
      staff_list: "openStaffProfile/staff",
      columns: "openStaffProfile/columns",
      rules: "openStaffProfile/rules",
      mode: "MODE"
    }),
  },

  methods: {
    ...mapActions({
      show: "openStaffProfile/show",
      updateList: "openStaffProfile/oneStaff",
    }),
    fetchStaff() {
      if (this.$route.params.staffUuid) {
        this.show(this.$route.params.staffUuid)
          .then((res) => {
            this.staffCheck = true;
          })
          .catch((err) => {
            this.staffCheck = false;
          });
      }
    },
    table() {
      const today = new Date();
      var nur = new Date(today.getFullYear(), today.getMonth(), 1)
      this.firstDay = nur.getDay();
      var query = {
        'uuid': this.$route.params.staffUuid,
        'select_date': this.select_date
      }
      this.updateList(query).then((res) => {

      });
    },
    closeStaffAttendanceModal(val) {
      this.showStaffAttendance = val;
    },
    drawerStaffAttendanceOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened(this.child);
        }
      }
    },
  },
};
</script>
<style lang="scss">
.link-mobil {
  display: none;
}

.block_1,
.block_2 {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  position: relative;
}

.logins {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bekmega {
  width: 200px;
}

.gohr {
  width: 150px;
}

.bg-cwe {
  background-color: #dae6e9;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}

.container-my {
  width: 1500px;
  margin: auto;
}

@media (max-width: 1200px) {
  .container-my {
    width: 100%;
    margin: auto;
  }
}

@media (max-width: 500px) {
  .container-my {
    width: 100%;
    margin: auto;
  }
  .bekmega {
    width: 100px;
  }
  .link-mobil {
    width: 100%;
    background: #00c200;
    display: inline-block;
    color: #fff;
    top: -18px;
    position: absolute;
    right: 0px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
  }
}

@media (max-width: 320px) {
  .container-my {
    width: 100%;
    margin: auto;
  }
}

.my-calendar_two {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 1.5px;

}

.no_working {
  background-color: #2d2e2c7d !important;
  color: #fff;
}

.night_drawer {
  .el-drawer__body {
    background-color: #0f0e1e !important;
    overflow-x: scroll;
    overflow-y: scroll;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
}

.completion_percentage {
  position: absolute;
  top: 7px;
  right: 10px;
  display: inline-flex;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 800;
  z-index: 99;
}

@media only screen and (max-width: 600px) {
  .cardinto__day {
    .el-card__body {
      padding: 20px !important;
      flex-direction: column !important;
    }
  }
  .bg-cwe {
    padding: 10px !important;
  }
}

.page404 {
  position: relative;
  width: 100%;
  height: 100vh;

  .text404 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 100px;
    color: #0d67c9;
    font-weight: 900 !important;
    font-family: "M-Bold";
    margin: 0;

    span {
      color: #399919;
    }
  }
}

.setting-left-filter {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
  padding: 10px;
}

.setting-left-filter li {
  margin: 0;
}
</style>
