<template>
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header my-top-khan-title">
          <p class="large--title m-0" :class="mode ? '' : 'content__titlenight'"> {{ $t("message.journal") }}</p>
          <el-button @click="closeModal()" type="warning" plain> {{ $t("message.close") }}</el-button>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-row :gutter="20">
            <el-col :span="24">
              <table
                class="table-my-code table-bordered td-center"
                :class="mode ? 'table__myday' : 'table__mynight'"
                v-loading="loadStaff"
              >
                <thead>
                <tr>
                  <th>
                    {{ columns.img.title }}
                  </th>
                  <th>
                    {{ columns.staff_full_name.title }}
                  </th>
                  <th>
                    {{ columns.branch.title }}
                  </th>
                  <th>
                    {{ columns.punch_state.title }}
                  </th>
                  <th>
                    {{ columns.punch_time.title }}
                  </th>
                  <th>
                    {{ columns.device.title }}
                  </th>
                  <th>
                    {{ columns.created_at.title }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="att in list" :key="'atts-' + att.id">
                  <td :class="att.device?'':(mode ? 'td-warning' : '')">
                    <el-image
                      style="width: 40px; height: 40px"
                      :src="
                                      att.captured_img
                                        ? baseUrl + att.captured_img
                                        : 'img/avatar.jpeg'
                                    "
                    >
                      <!-- :preview-src-list="previewList" -->
                    </el-image>
                  </td>
                  <td :class="att.device?'':(mode ? 'td-warning' : '')">
                    {{
                      att.staff ? att.staff.name + " " + att.staff.last_name : ""
                    }}
                  </td>
                  <td :class="att.device?'':(mode ? 'td-warning' : '')">
                    {{
                      att.staff
                        ? att.staff.branch
                          ? att.staff.branch.name
                          : ""
                        : ""
                    }}
                  </td>
                  <td :class="att.device?'':(mode ? 'td-warning' : '')">{{ att.punch_state }}</td>
                  <td :class="mode ? 'td-success' : ''">
                    <el-button type="success" size="mini" icon="el-icon-time" round>
                      {{ att.punch_time }}
                    </el-button>
                  </td>
                  <td :class="att.device?'':(mode ? 'td-warning' : '')">
                    {{ att.device ? att.device.device_name : $t('message.manual') }}
                  </td>
                  <td :class="att.device?'':(mode ? 'td-warning' : '')">{{ att.created_at }}</td>
                </tr>
                </tbody>
              </table>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import drawer from "@/utils/mixins/drawer";

export default {
  name: "show-staff-attendance",
  mixins: [drawer],
  components: {},
  data() {
    return {
      loadStaff: false,
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      ),
    };
  },
  computed: {
    ...mapGetters({
      mode: "MODE",
      list: "attendanceReport/attendance_report",
      columns: "attendance/columns",
    }),
  },
  methods: {
    ...mapActions({
      getOneStaffOneDayAttendanceInfo: "attendanceReport/getOneStaffOneDayAttendance",
    }),

    opened(child) {
      if (!this.loadStaff) {
        this.loadStaff = true;
        this.getOneStaffOneDayAttendanceInfo(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
      }
    },
    closeModal() {
      this.$emit("closeStaffAttendanceModal", false);
    },
    pushToProfile(staff) {
      let route = this.$router.resolve({
        name: "profile",
        params: {
          id: staff.id,
        },
      });

      window.open(route.href, "_blank");
    },
  }
};
</script>
<style lang="scss">
.timeline-items__right {
  margin-bottom: 20px;

  .info-user-left2 {
    display: flex;
    align-items: start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .img {
      margin-right: 10px;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
  }

  .name_u {
    font-weight: bold;
    font-size: 15px;
  }

  .position_u {
    color: #8a8a8a;
    font-size: 12px;
    font-weight: 300;
    margin-top: 2px;
  }

  .salary {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
  }
}

.info-user-left2 {
  display: flex;
  align-items: center;
  //background-color: #fff;
  background-clip: border-box;
  // border: 1px solid rgba(34,41,47,.1254901961);
  border-radius: 0.428rem;
  // box-shadow: 0 4px 24px 0 rgb(34 41 47 / 1%);
  width: 100%;
  cursor: pointer;
  padding: 10px;
}

.my-top-khan-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 20px;
  flex-wrap: nowrap;
}

.info-user-left2 {
  flex-wrap: nowrap !important;
}

.elemet-content {
  padding: 0 15px;
  height: 100%;
  overflow: auto;
}

.app-modal__box {
  min-height: 100vh;
}

.el-drawer__body {
  overflow-y: scroll;
}

.tms-info-list {
  list-style: none;
  margin: 0;
  padding: 0;

  .tms-info-item {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 30px;
    font-size: 14px;
    padding: 8px 0px;
    border-bottom: 1px solid #cacaca93;

    b {
      width: 35%;
    }
  }
}

.performance__night {
  background-color: #0f0e1e !important;

}

</style>
